<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-form>
          <b-tabs
            pills
            class="mb-3"
          >
            <b-tab :title="$t('admin.tabs.base')">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    label-for="title"
                  >
                    <b-form-input
                      id="title"
                      v-model="item.title"
                      :state="errors && errors.title ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.title">
                      {{ errors.title[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.slug.label')"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.slug"
                      :state="errors && errors.slug ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.slug">
                      {{ errors.slug[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.template.label')"
                    label-for="template"
                    :state="errors && errors.template ? false : null"
                  >
                    <v-select
                      id="template"
                      v-model="item.template"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="templateOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="template"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.template">
                      {{ errors.template[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                  class="hidden"
                >
                  <b-form-group
                    :label="'Ціни для ролі'"
                    label-for="role_id"
                    :state="errors && errors.role_id ? false : null"
                  >
                    <v-select
                      id="role_id"
                      v-model="item.role_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="rolesOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="role_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.role_id">
                      {{ errors.role_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.currency.label')"
                    label-for="currency_code"
                    :state="errors && errors.currency_code ? false : null"
                  >
                    <v-select
                      id="currency_code"
                      v-model="item.currency_code"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="currencyOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="currency_code"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.currency_code">
                      {{ errors.currency_code[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.language.label')"
                    label-for="language_code"
                    :state="errors && errors.language_code ? false : null"
                  >
                    <v-select
                      id="language_code"
                      v-model="item.language_code"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="languages"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="language_code"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.language_code">
                      {{ errors.language_code[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.zero_qnt.label')"
                    label-for="zero_qnt"
                    :state="errors && errors.zero_qnt ? false : null"
                  >
                    <v-select
                      id="zero_qnt"
                      v-model="item.zero_qnt"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="zero_qnt"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.zero_qnt">
                      {{ errors.zero_qnt[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.zero_img.label')"
                    label-for="zero_img"
                    :state="errors && errors.zero_img ? false : null"
                  >
                    <v-select
                      id="zero_img"
                      v-model="item.zero_img"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="zero_img"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.zero_img">
                      {{ errors.zero_img[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.only_discount.label')"
                    label-for="only_discount"
                    :state="errors && errors.only_discount ? false : null"
                  >
                    <v-select
                      id="only_discount"
                      v-model="item.only_discount"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="only_discount"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.only_discount">
                      {{ errors.only_discount[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.tabs.categories')">
              <b-form-group
                :label="$t('form.categories.label')"
                label-for="categories"
                :state="errors && errors.categories ? false : null"
              >
                <v-select
                  id="categories"
                  v-model="item.categories"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoriesOptions"
                  :reduce="val => val.value"
                  :clearable="true"
                  :multiple="true"
                  input-id="categories"
                />
                <b-form-invalid-feedback v-if="errors && errors.categories">
                  {{ errors.categories[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-tab>
            <b-tab :title="$t('admin.tabs.attributes')">
              <div class="repeater-form">
                <b-row
                  v-for="(attribute, index) in item.attributes"
                  :id="'attribute' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-group
                      :label="$t('form.attribute_id.label')"
                      :label-for="'attributes.' + index + '.attribute_id'"
                      :state="errors && errors['attributes.' + index + '.attribute_id'] ? false : null"
                    >
                      <v-select
                        :id="'attributes.' + index + '.attribute_id'"
                        v-model="attribute.attribute_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="uniqAttributes"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'attributes.' + index + '.attribute_id'"
                        @search="searchAttribute"
                        @input="selectAttribute($event, index)"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_id']">
                        {{ errors['attributes.' + index + '.attribute_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-group
                      :label="$t('form.attribute_value_id.label')"
                      :label-for="'attributes.' + index + '.attribute_value_id'"
                      :state="errors && errors['attributes.' + index + '.attribute_value_id'] ? false : null"
                    >
                      <v-select
                        :id="'attributes.' + index + '.attribute_value_id'"
                        v-model="attribute.attribute_value_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="attributeValuesOptions[`attribute_${attribute.attribute_id}`]"
                        :disabled="!attributeValuesOptions[`attribute_${attribute.attribute_id}`]"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'attributes.' + index + '.attribute_value_id'"
                        :taggable="true"
                        :create-option="val => ({ value: `new_${val}`, label: `Добавить новое значение ${val}` })"
                        @search="searchAttributeValue($event, attribute.attribute_id)"
                        @input="selectAttributeValue($event, attribute.attribute_id)"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_value_id']">
                        {{ errors['attributes.' + index + '.attribute_value_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeAttribute(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addAttribute"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row v-if="errors && errors.attributes">
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.attributes[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.tabs.continue')">
              <b-alert
                variant="info"
                show
                class="mb-3 p-1"
              >
                Буде виконано пропуск товарів, які підпадають хоча б під одне із правил нижче.
              </b-alert>
              <b-row>
                <b-col cols="2">
                  <b-button
                    variant="primary"
                    block
                    @click="addContinue"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                  </b-button>
                </b-col>
                <b-col cols="10">
                  <div class="repeater-form">
                    <b-row
                      v-for="(cont, index) in item.continue_if"
                      :id="'cont' + index"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group>
                          <v-select
                            v-model="cont.f"
                            :options="ciFieldOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <b-form-group>
                          <v-select
                            v-model="cont.c"
                            :options="ciCompareOptions"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group>
                          <b-form-input v-model="cont.v" />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <b-button
                          variant="danger"
                          block
                          @click="removeContinue(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                          />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'feeds-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import CategoryMixin from '@/mixins/CategoryMixin'
import _ from 'lodash'

export default {
  mixins: [GlobalMixin, CategoryMixin],
  data() {
    return {
      item: {
        status: 'enabled',
        zero_qnt: 'disabled',
        zero_img: 'disabled',
        only_discount: 'disabled',
        title: null,
        template: null,
        slug: null,
        role_id: 2,
        language_code: 'uk',
        currency_code: 'uah',
        categories: [],
        attributes: [],
        continue_if: [],
        custom_fields: [],
      },
      languages: [],
      currencyOptions: [],
      rolesOptions: [],
      categoriesOptions: [],
      attributesOptions: [],
      attributesBaseOptions: [],
      attributeValuesOptions: {},
      attributeBaseValuesOptions: {},
      ciFieldOptions: [
        { label: 'Ціна', value: 'price' },
        { label: 'Кількість', value: 'quantity' },
        { label: 'Категорія', value: 'category' },
        { label: 'Назва', value: 'title' },
      ],
      ciCompareOptions: ['==', '!=', '>=', '<=', 'like', 'not like'],
      templateOptions: [
        { label: 'Default', value: null },
        { label: 'Hotline', value: 'HotlineMapper' },
        { label: 'HotlineSpecials', value: 'HotlineSpecialsMapper' },
        { label: 'Allo', value: 'AlloMapper' },
        { label: 'Google', value: 'GoogleMapper' },
        { label: 'Facebook', value: 'FacebookMapper' },
        { label: 'Yml', value: 'YmlMapper' },
        { label: 'YmlExpress', value: 'YmlExpressMapper' },
        { label: 'Price', value: 'PriceMapper' },
        { label: 'Reviews', value: 'ReviewsMapper' },
      ],
    }
  },
  computed: {
    uniqAttributes() {
      return _.uniqBy(_.concat(this.attributesBaseOptions, this.attributesOptions), 'value')
    },
  },
  beforeCreate() {
    this.$http.get('/api/roles', { params: { mode: 'client', per_page: 100 } })
      .then(response => {
        this.rolesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })
    this.$http('/api/currencies')
      .then(response => {
        this.currencyOptions = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })
    this.$http.get('/api/languages')
      .then(response => {
        this.languages = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })
  },
  created() {
    this.loadDataTab()
  },
  methods: {
    loadDataTab() {
      if (!Object.keys(this.categoriesOptions).length) {
        this.$http.get('/api/admin/fullcategories', {
          params: {
            type: 'product',
            status: 'enabled',
          },
        })
          .then(response => {
            this.categoriesOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
          })
      }

      if (!Object.keys(this.attributesOptions).length) {
        this.$http.get('/api/attributes', {
          params: {
            sort_by: 'title', sort_desc: false, status: 'enabled', per_page: 20,
          },
        })
          .then(response => {
            this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
          })
      }
    },
    addContinue() {
      this.item.continue_if.push({
        f: 'price',
        c: '==',
        v: 0,
      })
    },
    removeContinue(index) {
      this.item.continue_if.splice(index, 1)
    },
    addAttribute() {
      this.item.attributes.push({
        attribute_id: null,
        attribute_value_id: null,
      })
    },
    removeAttribute(index) {
      this.item.attributes.splice(index, 1)
    },
    async selectAttribute(id, index) {
      this.attributesBaseOptions.push(this.attributesOptions.find(a => a.value === id))
      this.item.attributes[index].attribute_value_id = null

      const response = await this.$http.get(`/api/attributes/${id}/values`)

      if (!_.has(this.attributeValuesOptions, `attribute_${id}`)) {
        this.$set(this.attributeValuesOptions, `attribute_${id}`, [])
      }
      if (!_.has(this.attributeBaseValuesOptions, `attribute_${id}`)) {
        this.$set(this.attributeBaseValuesOptions, `attribute_${id}`, [])
      }

      this.attributeValuesOptions[`attribute_${id}`] = _.uniqBy(_.concat(this.attributeBaseValuesOptions[`attribute_${id}`], this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')), 'value')
    },
    selectAttributeValue(id, aid) {
      this.attributeBaseValuesOptions[`attribute_${aid}`].push(this.attributeValuesOptions[`attribute_${aid}`].find(av => av.value === id))
    },
    async onSubmit() {
      const data = {
        title: this.item.title,
        slug: this.item.slug,
        status: this.item.status,
        zero_qnt: this.item.zero_qnt,
        zero_img: this.item.zero_img,
        template: this.item.template,
        role_id: this.item.role_id,
        language_code: this.item.language_code,
        currency_code: this.item.currency_code,
        only_discount: this.item.only_discount,
        categories: this.item.categories,
        attributes: _.map(this.item.attributes, 'attribute_value_id'),
        continue_if: this.item.continue_if,
        custom_fields: this.item.custom_fields,
      }

      this.$http.post('/api/admin/feeds', data)
        .then(() => {
          router.replace({ name: 'feeds-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    async searchAttribute(query) {
      if (!query.length) return
      await this.$http.get('/api/attributes', { params: { query, per_page: 20 } })
        .then(response => {
          this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async searchAttributeValue(query, id) {
      if (!query.length) return
      await this.$http.get(`/api/attributes/${id}/values`, { params: { query, per_page: 20 } })
        .then(response => {
          this.attributeValuesOptions[`attribute_${id}`] = _.uniqBy(_.concat(this.attributeBaseValuesOptions[`attribute_${id}`], this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')), 'value')
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
